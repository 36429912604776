<template>
    <div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-sm btn-primary float-right" @click="addNew()">
                    Add Consignee
                </button>
            </div>
            <div class="col-12" v-if="!showAddConsigne">
                <div class="card card-custom gutter-b">
                    <div class="card-body body-fluid">
                        <div class="table-responsive">
                            <div>
                                <b-table :items="consignes" :fields="fields" :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc" responsive="sm">
                                    <template #cell(name)="data">
                                        <a class="a" @click="GetRecord(data.item)">{{ data.value }}</a>
                                    </template>
                                    <template #cell(actions)="row">
                                        <b-button @click="viewContainer(row.item.id)" class="btn-sm" variant="primary">
                                            View</b-button>
                                        <!-- <b-button @click="deleteContainer(row.item.id)" class="btn-sm ml-2"
                                            variant="danger">Delete</b-button> -->
                                    </template>
                                </b-table>

                                <div>
                                    Sorting By: <b>{{ sortBy }}</b>, Sort Direction:
                                    <b>{{ sortDesc ? 'Descending' : 'Ascending' }}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showAddConsigne">
            <div class="row mt-5">
                <div class="col-md-10 offset-md-1">
                    <div class="card card-custom gutter-b">
                        <div class="card-header">
                            <div class="card-title">
                                <h3 class="card-label">Add Consigne Details</h3>
                            </div>
                        </div>
                        <div class="card-body body-fluid">

                            <form ref="editform" @submit.stop.prevent="handleSubmit" v-on:submit="submitConsigne">
                                <div class="row">
                                    <div class="col-md-4">
                                        <b-form-group label="Name">
                                            <b-form-input v-model="form.name"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-group label="Email">
                                            <b-form-input v-model="form.email"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-group label="Phone">
                                            <b-form-input type="text" v-model="form.phone"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-12">
                                        <b-form-group label="Address">
                                            <b-form-input type="text" v-model="form.address"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-3">
                                        <b-form-group label="City">
                                            <b-form-input type="text" v-model="form.city"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-3">
                                        <b-form-group label="State">
                                            <b-form-input type="text" v-model="form.state"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-3">
                                        <b-form-group label="Zipcode">
                                            <b-form-input type="text" v-model="form.zipcode"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-3">
                                        <b-form-group label="Country">
                                            <b-form-select v-model="form.country" :options="countries" value-field="name"
                                                text-field="name"></b-form-select>
                                        </b-form-group>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group float-left">
                                            <button type="button" class="btn btn-info float-left" @click="backToList()">
                                                Back To List </button>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group float-right">
                                            <button type="submit" class="btn btn-primary float-right"
                                                @click="addConsignee()"> Submit </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loading :active.sync="isLoading"></loading>
    </div>
</template>

<script>
import {
    SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
    mapGetters
} from "vuex";
import { throws } from "assert";
export default {
    data() {
        return {
            isLoading: false,
            showAddConsigne: false,
            isEditCall: false,
            consignes: [],
            countries: [],
            states: [],
            form: {
                id: null,
                name: null,
                email: null,
                phone: null,
                address: null,
                city: null,
                state: null,
                zipcode: null,
                country: null,
            },
            sortDesc: false,
            fields: [{
                key: "id",
                label: "ID",
                sortable: true,
            },
            {
                key: "name",
                label: "Name",
                sortable: true,
            },
            {
                key: "email",
                label: "Email",
                sortable: true,
            },
            {
                key: "phone",
                label: "Phone",
                sortable: true,
            },
            {
                key: "address",
                label: "Address",
                sortable: true,
            },
            {
                key: "actions",
                label: "Actions",
            },
            ],
        };
    },
    computed: {
        ...mapGetters(["currentUser"]),
    },
    components: {
        Loading,
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{
            title: "Consignees",
        },
        {
            title: "All Consignees",
        },
        ]);
        this.getActiveConsigne();
    },
    methods: {
        addNew() {
            this.showAddConsigne = true;
            this.isEditCall = false;
        },
        GetRecord(row) {

            var consigne = this.consignes.filter(x => x.id == row.id)[0];
            this.form = {
                id: consigne.id,
                name: consigne.name,
                email: consigne.email,
                phone: consigne.phone,
                address: consigne.address,
                city: consigne.city,
                state: consigne.state,
                zipcode: consigne.zipcode,
                country: consigne.country,
            };

            this.showAddConsigne = true;
            this.isEditCall = true;
        },
        getActiveConsigne() {
            ApiService.get("pageload")
                .then(({
                    data
                }) => {
                    this.consignes = data.consignes;
                    this.countries = data.countries;
                    this.states = data.states;
                    this.rows = this.consignes.length;
                    this.isLoading = false;
                })
                .catch(() => { });
        },
        backToList() {
            this.showAddConsigne = false;
        },
        addConsignee() {
            if (this.checkErrors()) {
                this.isLoading = true;
                if (!this.isEditCall) {
                    ApiService.post("consignee", this.form)
                        .then(({ data }) => {
                            this.$nextTick(() => {
                                this.makeToastVariant(
                                    "success",
                                    "Consignee added successfully",
                                    "Success"
                                );

                                this.getActiveConsigne();
                                this.showAddConsigne = false;
                                this.isEditCall = false;
                                this.resetForm();
                            });
                        })
                        .catch(({
                            response
                        }) => {
                            this.isLoading = false;
                            for (let field of Object.keys(response.data.error)) {
                                this.makeToastVariant(
                                    "danger",
                                    response.data.error[field][0],
                                    "Error"
                                );
                            }
                        });
                } else {
                    ApiService.update("consignee", this.form.id, this.form)
                        .then(() => {
                            this.getActiveConsigne();
                            this.showAddConsigne = false;
                            this.isEditCall = false;
                            this.resetForm();
                            this.$nextTick(() => {

                                this.makeToastVariant(
                                    "info",
                                    "Consigne updated successfully",
                                    "Success"
                                );
                            });
                        })
                        .catch(({ response }) => {
                            this.isLoading = false;
                            for (let field of Object.keys(response.data.error)) {
                                this.makeToastVariant(
                                    "danger",
                                    response.data.error[field][0],
                                    "Error"
                                );
                            }
                        });
                }

            }
        },
        checkErrors() {
            if (
                this.form.name &&
                this.form.email &&
                this.form.phone &&
                this.form.address &&
                this.form.city &&
                this.form.country
            ) {
                return true;
            }
            this.errors = [];
            if (!this.form.name) {
                this.errors.push("Consigne name is required.");
            }
            if (!this.form.email) {
                this.errors.push("Consigne email is required.");
            }
            if (!this.form.phone) {
                this.errors.push("Consigne phone is required.");
            }
            if (!this.form.address) {
                this.errors.push("Consigne address is required.");
            }
            if (!this.form.city) {
                this.errors.push("Consigne city is required.");
            }
            if (!this.form.country) {
                this.errors.push("Consigne country is required.");
            }
            for (let field of this.errors) {
                this.makeToastVariant("danger", field, "Error");
            }
        },
        makeToastVariant(variant = null, text) {
            this.$bvToast.toast(text, {
                title: `Error`,
                variant: variant,
                solid: true,
            });
        },
        viewContainer(row) {

            this.$router.push({
                name: "viewcontainer",
                params: {
                    id: row
                }
            });
        },
        resetForm(){
            this.form= {
                id: null,
                name: null,
                email: null,
                phone: null,
                address: null,
                city: null,
                state: null,
                zipcode: null,
                country: null,
            };
        }
    },
};
</script>
